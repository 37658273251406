// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/Paragraph.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/Paragraph.tsx");
  import.meta.hot.lastModified = "1709804301422.5168";
}
// REMIX HMR END

import { cn } from "~/utils/cn";
const paragraphVariants = {
  base: {
    text: "font-sans text-base font-normal text-text-dimmed",
    spacing: "mb-3"
  },
  "base/bright": {
    text: "font-sans text-base font-normal text-text-bright",
    spacing: "mb-3"
  },
  small: {
    text: "font-sans text-sm font-normal text-text-dimmed",
    spacing: "mb-2"
  },
  "small/bright": {
    text: "font-sans text-sm font-normal text-text-bright",
    spacing: "mb-2"
  },
  "extra-small": {
    text: "font-sans text-xs font-normal text-text-dimmed",
    spacing: "mb-1.5"
  },
  "extra-small/bright": {
    text: "font-sans text-xs font-normal text-text-bright",
    spacing: "mb-1.5"
  },
  "extra-small/mono": {
    text: "font-mono text-xs font-normal text-text-dimmed",
    spacing: "mb-1.5"
  },
  "extra-small/bright/mono": {
    text: "font-mono text-xs text-text-bright",
    spacing: "mb-1.5"
  },
  "extra-small/caps": {
    text: "font-sans text-xs uppercase tracking-wider font-normal text-text-dimmed",
    spacing: "mb-1.5"
  },
  "extra-small/bright/caps": {
    text: "font-sans text-xs uppercase tracking-wider font-normal text-text-bright",
    spacing: "mb-1.5"
  },
  "extra-extra-small": {
    text: "font-sans text-xxs font-normal text-text-dimmed",
    spacing: "mb-1"
  },
  "extra-extra-small/bright": {
    text: "font-sans text-xxs font-normal text-text-bright",
    spacing: "mb-1"
  },
  "extra-extra-small/caps": {
    text: "font-sans text-xxs uppercase tracking-wider font-normal text-text-dimmed",
    spacing: "mb-1"
  },
  "extra-extra-small/bright/caps": {
    text: "font-sans text-xxs uppercase tracking-wider font-normal text-text-bright",
    spacing: "mb-1"
  },
  "extra-extra-small/dimmed/caps": {
    text: "font-sans text-xxs uppercase tracking-wider font-normal text-text-dimmed",
    spacing: "mb-1"
  }
};
export function Paragraph({
  variant = "base",
  className,
  spacing = false,
  children,
  ...props
}) {
  return <p className={cn(paragraphVariants[variant].text, spacing === true && paragraphVariants[variant].spacing, className)} {...props}>
      {children}
    </p>;
}
_c = Paragraph;
var _c;
$RefreshReg$(_c, "Paragraph");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;